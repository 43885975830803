import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import SelectCustom from 'react-select';
import { apiGet, apiPost } from '../../../../api';
import { useFetch } from '../../../../hooks/useFetch';
import { countNotReprimanded } from '../../../../redux/actions/user';
import { Breadcrumbs, Input, Modal, Select, Textarea } from '../../../components';
import FirefoxDateTime from '../../../components/FireFoxDateTime/FirefoxDateTime';
import './index.scss';

const CreateSummary = () => {
  const { id, templateId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [typeSummaryReport, setTypeSummaryReport] = useState(0);
  const [reportDate, setReportDate] = useState(0);
  const [dataTriggerColumns, setDataTriggerColumns] = useState();
  const [triggerColumnsId, setTriggerColumnsId] = useState(null);
  const [modalViewTrigger, setModalViewTrigger] = useState(false);
  const [triggerData, setTriggerData] = useState('');
  const [dataTriggerColumnsLetters, setDataTriggerColumnsLetters] = useState();
  const [afterElementId, setAfterElementId] = useState('');
  const [searchAfterElementId, setSearchAfterElementId] = useState('');
  const [errObj, setErrObj] = useState({});
  const [reprimanded, setReprimanded] = useState(false);

  const [reportList, setReportList] = useState([{ name: 'Выбрать', id: null }]);
  const [typeReportList, setTypeReportList] = useState();

  const changeCheckbox = index => {
    const tmp = [...dataTriggerColumns];
    tmp[index].enable = !dataTriggerColumns[index].enable;

    setDataTriggerColumns(tmp);
  };

  const { response: reportsListData } = useFetch({
    url: '/report?limit=10000',
  });

  const { response: afterElementsData } = useFetch({
    url: `/summary-report-template?limit=10&template_id=${templateId}&name=${searchAfterElementId || ''}`,
  });

  const handleOnDragEnd = result => {
    if (!result.destination) return;
    const items = Array.from(dataTriggerColumns);
    const [reorderItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderItem);

    setDataTriggerColumns(items);
  };

  const downloadSummaryReport = () => {
    const arrayTriggers = dataTriggerColumns;
    for (let i = 0; i < arrayTriggers.length; i++) {
      delete arrayTriggers[i].column;
      delete arrayTriggers[i].triggerName;
    }
    const postData = {
      triggerColumns: JSON.stringify(arrayTriggers),
    };

    apiPost({
      url: `/summary-report/create-trigger-columns?id=${triggerColumnsId}`,
      postData,
    }).then(res => {
      if (res.error) {
        emptyCreateSummaryReport('Ошибка');
      } else {
        // downloadBase64File(res.data.asBase64, res.data.fileName)

        apiGet({ url: '/report/not-reprimanded-count' }).then(res => {
          dispatch(countNotReprimanded(res.data));
        });

        history.goBack();
      }
    });
  };

  const linkRoute = [
    {
      name: 'Отчеты',
      link: '/reports',
    },
    {
      name: 'Отчет организаций',
      link: `/organization-reports/${id}`,
    },
    {
      name: 'Сводные отчеты',
      link: `/summary-reports/${id}/${templateId}`,
    },
    {
      name: 'Создание сводного отчета',
      link: `/create-summary-report/${id}`,
    },
  ];

  const dataTypeSummaryReport = [
    { name: 'Выберите приложение', id: 0 },
    { name: 'Приложение 2', id: 1 },
    { name: 'Приложение 3', id: 2 },
    { name: 'Приложение 4 или 5', id: 3 },
    { name: 'Приложение 2 (с 2кв 2021г)', id: 4 },
    { name: 'Приложение 2 (с 1кв 2022г)', id: 5 },
    { name: 'Приложение 3 (с 1кв 2022г)', id: 6 },
    { name: 'Отчет по Риск-профилю', id: 7 },
    { name: 'БФО КО 613', id: 8 },
    { name: 'БФО КО 614', id: 9 },
    { name: 'БФО НО 613', id: 10 },
    { name: 'БФО НО 614', id: 11 },
  ];

  const emptyCreateSummaryReport = text => {
    confirmAlert({
      message: text,
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const reformatDate = dateStr => {
    return dateStr.replace(/(\d+)-(\d+)-(\d+)T(\d+):(\d+)/, '$3.$2.$1 $4:$5');
  };

  const createSummaryReport = () => {
    let postData = {
      type: typeSummaryReport,
      reportTime: reportDate,
      summaryReportTemplate: afterElementId.value,
      setAsReprimanded: reprimanded,
    };

    if (typeReportList && typeReportList !== 'Выбрать') {
      postData.additionalReports = typeReportList;
    }

    apiPost({
      url: `/summary-report/create?report_id=${id}`,
      postData,
    }).then(res => {
      if (res.error) {
        if (res.data.reportTime) {
          emptyCreateSummaryReport('Укажите дату');
        }
        if (res.data.type) {
          emptyCreateSummaryReport(res.data.type);
        }
        if (res.error) {
          setErrObj(res.data);
        }
      } else {
        setErrObj({});
        // setReprimanded(false)
        setTriggerColumnsId(res.data.id);
        setDataTriggerColumns(res.data.triggerColumns);
        setDataTriggerColumnsLetters(res.data.triggerColumns);
        emptyCreateSummaryReport('Сводный отчет успешно сформирован');
      }
    });
  };

  const viewTriigerInfo = id => {
    apiGet({
      url: `/trigger/view?id=${id}`,
    }).then(res => {
      if (res.error) {
        alert('Ошибка');
        return;
      }
      setModalViewTrigger(true);
      setTriggerData(res);
    });
  };

  useEffect(() => {
    if (reportsListData?.models) {
      setReportList([
        ...reportList,
        ...reportsListData.models.map(i => {
          return {
            name: `${i?.template?.name} ${i?.period?.name}`,
            id: i.id,
          };
        }),
      ]);
    }
  }, [reportsListData]);

  return (
    <div className="edit-summary">
      <Breadcrumbs linkRoute={linkRoute} />
      <div className="card">
        <div className="card-header">Создание сводного отчета</div>
        <div className="card-content">
          <div className="row">
            <div className="col-12 col-md-6 ">
              {navigator.userAgent.includes('Firefox') ? (
                <FirefoxDateTime label="Время отчета" onChange={e => setReportDate(e)} error={errObj.reportTime} />
              ) : (
                <Input
                  label="Время отчета"
                  type="datetime-local"
                  error={errObj.reportTime}
                  onChange={e => setReportDate(reformatDate(e))}
                />
              )}
            </div>

            <div className="col-12 col-md-6 ">
              <Select
                label="Тип отчета"
                error={errObj.type}
                value={typeSummaryReport}
                onChange={e => setTypeSummaryReport(e)}
                values={dataTypeSummaryReport}
              />
            </div>
            <div className="col-12 col-md-6 ">
              <label className="form-field form-label size">Шаблон сводного отчета по триггерам</label>
              {afterElementsData && (
                <SelectCustom
                  className={`${errObj.summaryReportTemplate && '_error'} select-company p-0`}
                  classNamePrefix="select"
                  placeholder=""
                  options={afterElementsData?.models.map(i => ({
                    value: i.id,
                    label: i.name,
                  }))}
                  value={afterElementId}
                  onChange={setAfterElementId}
                  onInputChange={e => setSearchAfterElementId(e)}
                  noOptionsMessage={() => 'Нет вариантов..'}
                />
              )}
              {errObj.summaryReportTemplate &&
                errObj.summaryReportTemplate.map(i => (
                  <span className="_error" key={i}>
                    {i}
                  </span>
                ))}
            </div>
            <div className="col-12 col-md-6 ">
              <Select
                label="Отчет"
                error={errObj.type}
                value={typeReportList}
                onChange={e => setTypeReportList(e)}
                values={reportList}
              />
            </div>
            <div className="col-12">
              <div className="col-auto mb-3" style={{ display: 'inline-block' }}>
                <label className="d-flex">
                  <input
                    type="checkbox"
                    className="checkbox mr-2"
                    checked={reprimanded}
                    onChange={() => setReprimanded(!reprimanded)}
                  />
                  <span>Отметить как выгруженные</span>
                </label>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-auto">
              <button className="btn btn-primary" onClick={() => createSummaryReport()}>
                Сформировать отчет
              </button>
            </div>
          </div>
          {dataTriggerColumnsLetters && (
            <>
              <div className="row sorting">
                <div className="col-auto">
                  <ul className="sirting-ul list-unstyled">
                    {dataTriggerColumnsLetters.map(({ column }, index) => (
                      <li key={index} style={{ height: '32px' }}>
                        {column}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col tasks">
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="dataTriggerColumns">
                      {provided => (
                        <ul className="tasks__list list-unstyled" {...provided.droppableProps} ref={provided.innerRef}>
                          {dataTriggerColumns.map(({ trigger_id, triggerName, enable }, index) => {
                            return (
                              <Draggable key={trigger_id + ''} draggableId={trigger_id + ''} index={index}>
                                {provided => (
                                  <li
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    className="tasks__item"
                                    onClick={e => {
                                      e.target.localName == 'span' && viewTriigerInfo(trigger_id);
                                    }}
                                  >
                                    <span>{triggerName}</span>

                                    <div
                                      onClick={() => changeCheckbox(index)}
                                      className={`custom-checkbox ${enable && '_active'}`}
                                    >
                                      <div className="circle"></div>
                                    </div>
                                  </li>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
              <button className="btn mr-1  btn-primary" onClick={() => downloadSummaryReport()}>
                Сохранить
              </button>
            </>
          )}
        </div>
      </div>
      <Modal show={modalViewTrigger} onClose={() => setModalViewTrigger(false)} modalTitle="Редактирование">
        <div className="row">
          <div className="col-12">
            <Input label="Название триггера" disabled={true} value={triggerData?.data?.name || ''} onChange={e => e} />
            <Input
              label="Период"
              disabled={true}
              value={`${triggerData?.data?.period?.startDate || ''} ${
                triggerData?.data?.period?.startDate ? '-' : ''
              }  ${triggerData?.data?.period?.endDate || ''}`}
              onChange={e => e}
            />
            <Textarea
              label="Код триггера"
              rows="10"
              value={triggerData?.data?.condition || ''}
              disabled={true}
            ></Textarea>
            <Textarea
              label="Описание триггера"
              rows="10"
              value={triggerData?.data?.description || ''}
              disabled={true}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateSummary;
